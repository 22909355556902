import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/core';

import { colors } from '@theme';
import { H2, H4, Text } from '@typography';
import { Tag } from '@zeta';
import { cloudfrontBaseUrl } from '../../lib/consts';
import TextWithImageRow from '../zeta/TextWithImageRow';
import Link from '../../Navigation/Link';

const KeyFeatures = () => {
  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';

  const personalFeatures1 = `${cloudfrontBaseUrl}/homepage-24/personal-accounts/personal-features1.jpg`;
  const personalFeatures2 = `${cloudfrontBaseUrl}/homepage-24/personal-accounts/personal-features2apy-2.09.jpg`;
  const personalFeatures3 = `${cloudfrontBaseUrl}/homepage-24/personal-accounts/personal-features3.jpg`;
  const personalFeatures4 = `${cloudfrontBaseUrl}/homepage-24/personal-accounts/personal-features4.jpg`;
  const personalFeatures1Mobile = `${cloudfrontBaseUrl}/homepage-24/personal-accounts/personal-features1-mobile.png`;
  const personalFeatures2Mobile = `${cloudfrontBaseUrl}/homepage-24/personal-accounts/personal-features2apy-2.09-mobile.jpg`;
  const personalFeatures3Mobile = `${cloudfrontBaseUrl}/homepage-24/personal-accounts/personal-features3-mobile-new.png`;
  const personalFeatures4Mobile = `${cloudfrontBaseUrl}/homepage-24/personal-accounts/personal-features4-mobile.png`;

  return (
    <Flex paddingBottom={[10, 24]} paddingX={[0, 16, 16, 8]} direction="column">
      <Flex
        justify="center"
        align="center"
        direction="column"
        textAlign="center"
        mb={[8]}
        px={[4, 0]}
      >
        <H2
          style={{
            color: colors.black,
          }}
        >
          Personal account key features
        </H2>
        <Flex mt="12px" mb={8} justify="center">
          <H4 center lineHeight={['1.2']} color={colors.grayGray900}>
            An account designed to simplify your life.
          </H4>
        </Flex>
      </Flex>

      <TextWithImageRow
        tag="bill reserve"
        title={`Let our bill manager do the grunt work`}
        body={`Never miss a bill payment. We'll automatically identity and plan for your bills and set aside funds for them. We can even make bill payments via check, debit card or ACH — all handled by Zeta.`}
        imageOnRight={true}
        image={personalFeatures1}
        mobileImage={personalFeatures1Mobile}
        mobileBackground={'#FDFBF0'}
        fullWidthMobileBackground={false}
        smallerText={true}
      />

      <TextWithImageRow
        tag="goals"
        title={`Save like a pro`}
        body={
          <Box>
            Achieve your goals with ease! Divide your savings into envelopes so that you
            know exactly how your tracking towards your dreams — and earn interest on your
            entire account as a VIP
            <Link to={`${pathname}#zeta-disclosure`} color={colors.greenPea}>
              ²
            </Link>{' '}
            with no limits.
          </Box>
        }
        imageOnRight={false}
        image={personalFeatures2}
        mobileImage={personalFeatures2Mobile}
        mobileBackground={'#FDFBF0'}
        smallerText={true}
      />

      <TextWithImageRow
        tag="automate your money"
        title={`Stress-free finances`}
        body={`Ensure your financial life runs smoothly with rules that automatically save, manage, and track your money.`}
        imageOnRight={true}
        image={personalFeatures3}
        mobileImage={personalFeatures3Mobile}
        mobileBackground={'#FDFBF0'}
        smallerText={true}
      />

      <TextWithImageRow
        tag="security"
        title={`Built with bank-grade security standards`}
        body={`Your Zeta account is FDIC-insured up to the standard maximum of $250,000 per depositor. Plus two-factor authentication & data encryption keep your data secure. We don't store your external login credentials and will never sell your data.`}
        imageOnRight={false}
        image={personalFeatures4}
        mobileImage={personalFeatures4Mobile}
        mobileBackground={'#FDFBF0'}
        smallerText={true}
      />
    </Flex>
  );
};

export default KeyFeatures;
